<template>
  <b-card no-body class="cursor-pointer wameed-card">
    <b-card-header class="align-items-start tab-title">
      <h5 class="text-reg-18 text-font-main">
        {{ $t('company_details.title') }}
      </h5>
    </b-card-header>
    <wameed-form refs="" @submitAction="submitJob">
      <template slot="form-content" slot-scope="{ invalid }">
        <b-card-body
          class="position-relative py-5 justify-content-between px-5"
        >
          <div class="mb-2 mx-4">
            <h4 class="text-med-16 text-font-main">
              {{ $t('company_details.how_type_your_name') }}
            </h4>
            <h5 class="text-reg-16 text-font-sub">
              {{ $t('company_details.description') }}
            </h5>
          </div>
          <!-- form -->
          <div class="d-flex flex-wrap py-4">
            <b-col cols="12" md="6">
              <text-input
                id="twitter"
                v-model="twitter"
                :label="$t('common.twitter')"
                inputClasses="text-med-14 text-font-main"
                name="twitter"
                :placeholder="$t('common.twitter_placeholder')"
              />
            </b-col>
            <b-col cols="12" md="6">
              <text-input
                id="instagram"
                v-model="instagram"
                :label="$t('common.instagram')"
                inputClasses="text-med-14 text-font-main"
                name="instagram"
                :placeholder="$t('common.instagram_placeholder')"
              />
            </b-col>

            <b-col cols="12" md="6">
              <text-input
                id="facebook"
                v-model="facebook"
                :label="$t('common.facebook')"
                inputClasses="text-med-16 text-font-main"
                name="facebook"
                :placeholder="$t('common.facebook_placeholder')"
              />
            </b-col>
            <b-col cols="12" md="6">
              <text-input
                id="linkedin"
                v-model="linkedin"
                :label="$t('common.linkedin')"
                inputClasses="text-med-16 text-font-main"
                name="linkedin"
                :placeholder="$t('common.linkedin_placeholder')"
              />
            </b-col>
          </div>
        </b-card-body>
        <b-card-footer class="px-4 py-3">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="px-4 py-3">
              <wameed-btn
                classes="  text-med-16 text-white rounded-10"
                :title="$t('common.save_edited')"
                :disabled="invalid"
                type="submit"
                variant="main"
              />
            </div>
            <div class="px-4 py-3">
              <wameed-btn
                classes="  text-med-16 text-font-secondary rounded-10 "
                :title="$t('common.cancel')"
                type="button"
                variant="gray"
                @submitAction="cancelJob"
              />
            </div>
          </div>
        </b-card-footer>
      </template>
    </wameed-form>
  </b-card>
</template>

<script>
import WameedForm from '@/components/WameedForm.vue';

import TextInput from '@/components/TextInput.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import WameedAsideModal from '@/components/WameedAsideModal.vue';
import WameedSwitch from '@/components/WameedSwitch.vue';
import { mapActions, mapGetters } from 'vuex';

import TextEditor from '@/components/TextEditor.vue';
export default {
  components: {
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedAsideModal,
    WameedSwitch,
    WameedForm,
    TextEditor,
  },
  data() {
    return {
      twitter: null,
      instagram: null,
      facebook: null,
      linkedin: null,
    };
  },
  methods: {
    cancelJob() {
      //do something
    },
    submitJob() {
      var experience = [];
      this.jobExperiance.forEach((element) => {
        experience.push(element.id);
      });

      // this.addJob({
      //   name: this.jobName,
      //   image: this.jobIcon,
      //   section_id: this.jobSection.id,
      //   type: this.jobType.id,
      //   place: this.jobLocation.id,
      //   salary: this.jobSalary,
      //   end_date: this.jobEndDate.name,
      //   experience: experience,
      //   description: this.description,
      // });
    },
  },
};
</script>
 
