<template>
  <b-card no-body class="cursor-pointer wameed-card">
    <b-card-header class="align-items-start tab-title">
      <h5 class="text-reg-18 text-font-main">
        {{ $t('settings.company_data') }}
      </h5>
    </b-card-header>
    <wameed-form refs="" @submitAction="submitJob">
      <template slot="form-content" slot-scope="{ invalid }">
        <b-card-body
          class="position-relative py-5 justify-content-between px-5"
        >
          <!-- form -->
          <div class="d-flex flex-wrap py-4">
            <b-col cols="12" md="12">
              <multi-images-upload
                :max="1"
                clear-all=""
                :label="$t('personal_information.company_logo')"
                id="upload-logo"
                name="uploadLogo"
                rules="required"
                v-model="uploadLogo"
              />
            </b-col>
            <b-col cols="12" md="6">
              <text-input
                id="service-provider-name"
                v-model="serviceProviderName"
                :label="$t('personal_information.service_provider_name')"
                inputClasses="text-med-14 text-font-main"
                name="serviceProviderName"
                rules="required"
                placeholder="----------------"
              />
            </b-col>
            <b-col cols="12" md="6">
              <wameed-dropdown
                :label="$t('personal_information.service_provider_type')"
                :subLable="$t('personal_information.can_not_be_changed')"
                classes="  text-med-14 w-100"
                valueClasses="  text-med-14  text-font-secondary"
                title="title"
                v-model="serviceProviderType"
                variant="disable"
                :items="types"
                text="title"
                name="serviceProviderType"
                id="service-provider-type"
              />
            </b-col>
            <b-col cols="12" md="6">
              <text-input
                id="email"
                v-model="email"
                :label="$t('personal_information.email')"
                inputClasses="text-med-14 text-font-main"
                name="email"
                rules="required|email"
                placeholder="----------------"
              />
            </b-col>

            <b-col cols="12" md="6">
              <wameed-dropdown
                :label="$t('personal_information.city')"
                classes="  text-med-14 w-100"
                valueClasses="  text-med-14  text-font-secondary"
                title="title"
                v-model="city"
                variant="disable"
                :items="cities"
                text="title"
                name="city"
                id="city"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="12">
              <text-area
                id="personal-information"
                v-model="personalInformation"
                :label="$t('personal_information.personal_information')"
                inputClasses="text-med-14 text-font-main"
                name="personalInformation"
                rules="required"
                placeholder="----------------"
                rows="5"
              />
            </b-col>
            <b-col cols="12" md="12">
              <wameed-dropdown
                :label="$t('personal_information.experience')"
                classes="  text-med-14 w-100"
                valueClasses="  text-med-14  text-font-secondary"
                title="title"
                v-model="experience"
                variant="disable"
                :items="experiences"
                :multiple="true"
                text="title"
                name="experience"
                id="experience"
                rules="required"
              />
            </b-col>
          </div>
        </b-card-body>
        <b-card-footer class="px-4 py-3">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="px-4 py-3">
              <wameed-btn
                classes="  text-med-14 text-white rounded-10"
                :title="$t('common.save_edited')"
                :disabled="invalid"
                type="submit"
                variant="main"
              />
            </div>
            <div class="px-4 py-3">
              <wameed-btn
                classes="  text-med-14 text-font-secondary rounded-10 "
                :title="$t('common.cancel')"
                type="button"
                variant="gray"
                @submitAction="cancelJob"
              />
            </div>
          </div>
        </b-card-footer>
      </template>
    </wameed-form>
  </b-card>
</template>

<script>
import WameedForm from '@/components/WameedForm.vue';

import TextInput from '@/components/TextInput.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import WameedAsideModal from '@/components/WameedAsideModal.vue';
import WameedSwitch from '@/components/WameedSwitch.vue';
import { mapActions, mapGetters } from 'vuex';

import TextEditor from '@/components/TextEditor.vue';
import TextArea from '@/components/TextArea.vue';
import MultiImagesUpload from '@/components/multiImagesUpload.vue';
export default {
  components: {
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedAsideModal,
    WameedSwitch,
    WameedForm,
    TextEditor,
    TextArea,
    MultiImagesUpload,
  },
  data() {
    return {
      uploadLogo: null,
      serviceProviderName: null,
      serviceProviderType: null,
      email: null,
      city: null,
      personalInformation: null,
      experience: null,
      cities: [
        { title: 'Full Time', id: 1 },
        { title: 'Part Time', id: 2 },
      ],
      types: [
        { title: 'Full Time', id: 1 },
        { title: 'Part Time', id: 2 },
      ],
      experiences: [
        { title: 'experience one', id: 1 },
        { title: 'experience tow', id: 2 },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {
    cancelJob() {
      // do something
    },
    submitJob() {
      var experience = [];
      this.jobExperiance.forEach((element) => {
        experience.push(element.id);
      });

      // this.addJob({
      //   name: this.name,
      //   image: this.jobIcon,
      //   section_id: this.jobSection.id,
      //   type: this.jobType.id,
      //   place: this.jobLocation.id,
      //   salary: this.jobSalary,
      //   end_date: this.jobEndDate.name,
      //   experience: experience,
      //   description: this.description,
      // });
    },
  },
};
</script>
 
